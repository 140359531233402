/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://4vwxmacywrfazegoljj7igrwv4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ymbkb5nylzaf3cz4cnlsm4yzyu",
    "aws_cloud_logic_custom": [
        {
            "name": "offerings",
            "endpoint": "https://ceths8e08e.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "onboarding",
            "endpoint": "https://ztxek401oi.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:5f50de3a-11a0-4c0e-abdf-613fb5105782",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ZTJPnh5Ex",
    "aws_user_pools_web_client_id": "12dviip9fh5facr94f6rsbm2qo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dunkirk40434-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
